<template>
  <v-container>
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="5000"
      v-model="emailedToken"
    >
      <div class="text-body-1">Token sent to {{ email }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="emailedToken = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="5000"
      v-model="recordedVote"
    >
      <div class="text-body-1">Your vote was recorded</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="recordedVote = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-snackbar
      app
      top
      right
      multiLine
      color="error"
      timeout="5000"
      v-model="formError"
    >
      <div class="text-body-1">{{ formMessage }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="formError = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-row>
      <v-col offset="1" cols="10">
        <v-toolbar color="green" dark>
          <v-toolbar-title>Vote</v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col offset="1" cols="8">
        <v-text-field
          label="Email address"
          v-model="email"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" @click="generateToken">Generate Token</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="clickedGenerateToken">
      <v-col offset="1" cols="8">
        <v-text-field
          label="Token"
          v-model="token"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" @click="showCandidates">Show Candidates</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="ballotCandidates.length > 0">
      <v-col offset="1" cols="8">
        <v-card>
          <v-toolbar color="green" dark>
            <v-toolbar-title
              >{{ electionName }} (Choose
              {{ electionWinners }})</v-toolbar-title
            >
          </v-toolbar>
          <v-spacer></v-spacer>
          <v-list-item
            v-for="candidate in ballotCandidates"
            :key="candidate.id"
          >
            <v-list-item-content
              ><v-list-item-title
                ><v-checkbox
                  class="ml-2 mt-1 mb-1"
                  v-model="selectedCandidates"
                  :label="candidate.name"
                  :value="candidate.id"
                  dense
                  hide-details
                ></v-checkbox></v-list-item-title
            ></v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                label="Write-in candidate"
                v-model="writeInCandidate"
                outlined
                dense
                hide-details
                @change="writeInChangeCheck"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title>
                <v-btn color="green" @click="vote">Vote</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;

export default {
  name: "Election",

  data: () => ({
    version: "0.0.1",
    email: null,
    token: null,
    elections: [],
    candidates: [],
    voters: [],
    selectedCandidates: [],
    writeInCandidate: null,
    clickedGenerateToken: false,
    emailedToken: false,
    recordedVote: false,
    formMessage: null,
    formError: false,
  }),
  async mounted() {},
  watch: {
    selectedCandidates() {
      let writeInCount =
        this.writeInCandidate && this.writeInCandidate.length ? 1 : 0;
      if (
        this.selectedCandidates.length + writeInCount >
        this.electionWinners
      ) {
        this.formMessage = `election limited to ${this.electionWinners} choices`;
        this.formError = true;
        this.selectedCandidates.pop();
      }
    },
  },
  computed: {
    emailTrim: function () {
      return this.email.trim();
    },
    electionId: function () {
      if (
        this.elections.length > 0 &&
        this.voters.length > 0 &&
        this.elections[0].id === this.voters[0].election_id
      ) {
        return this.elections[0].id;
      } else {
        return null;
      }
    },
    electionName: function () {
      if (
        this.elections.length > 0 &&
        this.voters.length > 0 &&
        this.elections[0].id === this.voters[0].election_id
      ) {
        return this.elections[0].name;
      } else {
        return null;
      }
    },
    electionWinners: function () {
      if (
        this.elections.length > 0 &&
        this.voters.length > 0 &&
        this.elections[0].id === this.voters[0].election_id
      ) {
        return this.elections[0].winners;
      } else {
        return null;
      }
    },
    ballotCandidates: function () {
      return this.candidates.filter((a) => a.write_in === false);
    },
  },
  methods: {
    getApiData: async function (endpoint, varName) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data.sort(
              (a, b) => a.sequence - b.sequence
            ))
        );
    },
    generateToken: async function () {
      await axios
        .get(`/voter/email/${this.emailTrim}`)
        .then(
          (response) =>
            (this.voters = response.data.sort(
              (a, b) => b.election_id - a.election_id
            ))
        );
      if (this.voters.length > 0) {
        await this.getElections();
        await axios
          .post("/generate_token/", {
            election_id: this.electionId,
            email: this.emailTrim,
          })
          .then((response) => {
            if (response.status === 200) {
              let message = response.data.message[0];
              if (message === "inserted token") {
                this.emailedToken = true;
                this.clickedGenerateToken = true;
              } else {
                this.formMessage = message;
                this.formError = true;
              }
            }
          });
      } else {
        this.formMessage = `${this.emailTrim} not registered for election`;
        this.formError = true;
      }
    },
    getElections: async function () {
      const ts = new Date().toISOString();
      await this.getApiData(`/election/ts/${ts}`, "elections");
    },
    showCandidates: async function () {
      let token;
      await axios
        .get(`/token/token/${this.token}/`)
        .then((response) => (token = response.data[0]));
      if (
        token.election_id === this.electionId &&
        token.email === this.emailTrim
      ) {
        await this.getApiData(
          `/candidate/election_id/${this.electionId}`,
          "candidates"
        );
      }
    },
    checkboxChange: function (e) {
      console.log(e.target);
    },
    writeInChangeCheck: function () {
      let writeInCount =
        this.writeInCandidate && this.writeInCandidate.length ? 1 : 0;
      if (
        this.selectedCandidates.length + writeInCount >
        this.electionWinners
      ) {
        this.formMessage = `election limited to ${this.electionWinners} choices`;
        this.formError = true;
        this.writeInCandidate = null;
      }
    },
    vote: async function () {
      let doVote = true;
      let writeInCount =
        this.writeInCandidate && this.writeInCandidate.length ? 1 : 0;
      if (
        this.electionWinners >
        this.selectedCandidates.length + writeInCount
      ) {
        doVote = await this.$dialog.confirm({
          text: `<p>Warning: You only voted for ${
            this.selectedCandidates.length + writeInCount
          } out of ${
            this.electionWinners
          }.</p><p>Are you sure you want to submit a partial vote?</p>`,
          title: "Warning",
        });
      }
      if (doVote === true) {
        await axios
          .post("/vote/", {
            election_id: this.electionId,
            token: this.token,
            candidate_ids: this.selectedCandidates,
            write_in: this.writeInCandidate,
          })
          .then((response) => {
            if (response.status === 200) {
              let message = response.data.message[0];
              if (
                message === "recorded vote" ||
                message === "inserted/updated write-in"
              ) {
                this.recordedVote = true;
              } else {
                this.formMessage = message;
                this.formError = true;
              }
            }
          });
      }
    },
  },
};
</script>

<style>
/* Further condense vertical spacing */
.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
