<template>
  <election />
</template>

<script>
import Election from "@/components/Election.vue";

export default {
  name: "Home",
  components: {
    Election,
  },
};
</script>
