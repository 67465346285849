<template>
  <v-container>
    <v-row>
      <v-col offset="2" cols="8">
        <v-toolbar color="green" dark>
          <v-toolbar-title>Voters</v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col offset="2" cols="8">
        <v-select
          class="mb-2"
          label="Election"
          v-model="electionId"
          :items="elections"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          @change="getVoters"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="voters.length">
      <v-col offset="2" cols="8">
        <v-card>
          <v-list-item v-for="voter in voters" :key="voter.id">
            <v-list-item-content
              ><v-list-item-title
                ><v-icon color="green" v-if="voter.voted > 0"
                  >mdi-check-bold</v-icon
                >
                <v-icon color="red" v-else>mdi-alpha-x-box-outline</v-icon>
                {{ voter.email | stripDomain }}
              </v-list-item-title></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title>
                <v-btn color="primary" @click="getVoters">Refresh</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;

export default {
  name: "Voted",

  data: () => ({
    electionId: null,
    elections: [],
    voters: [],
  }),
  async mounted() {
    await this.getApiData(`/election/`, "elections");
  },
  filters: {
    stripDomain: function (value) {
      return value.replace(/@.*/, "");
    },
  },
  methods: {
    getApiData: async function (endpoint, varName) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data.sort(
              (a, b) => a.sequence - b.sequence
            ))
        );
    },
    getVoters: async function () {
      this.getApiData(`/voter/election_id/${this.electionId}`, "voters");
    },
  },
};
</script>

<style>
/* Further condense vertical spacing */
.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
