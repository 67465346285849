<template>
  <v-container>
    <v-row>
      <v-col offset="2" cols="8">
        <v-toolbar color="green" dark>
          <v-toolbar-title>Results</v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col offset="2" cols="8">
        <v-select
          class="mb-2"
          label="Election"
          v-model="electionId"
          :items="elections"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          @change="getCandidates"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="electionResults.length">
      <v-col offset="2" cols="8">
        <v-card>
          <v-spacer></v-spacer>
          <v-list-item v-for="candidate in electionResults" :key="candidate.id">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon color="green" v-if="candidate.winner === 'true'"
                  >mdi-check-bold</v-icon
                ><v-icon color="orange" v-else-if="candidate.winner === 'tie'"
                  >mdi-check-bold</v-icon
                ><v-icon color="white" v-else
                  >mdi-checkbox-blank-outline</v-icon
                >
                {{ candidate.name
                }}<span v-if="candidate.write_in"> (write-in)</span>:
                {{ candidate.votes }} votes
              </v-list-item-title></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon
                  color="green"
                  v-if="voterVoteTotal === candidateVoteTotal"
                  >mdi-check-bold</v-icon
                >
                <v-icon color="red" v-else>mdi-alpha-x-circle</v-icon>
                {{ this.voterVoteTotal }} shares voted =
                {{ this.candidateVoteTotal }} total shares for all candidates
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title>
                <v-btn color="primary" @click="getCandidates">Refresh</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;

export default {
  name: "Results",

  data: () => ({
    electionId: null,
    elections: [],
    voters: [],
    candidates: [],
  }),
  async mounted() {
    await this.getApiData(`/election/`, "elections");
  },
  computed: {
    electionWinners: function () {
      return this.elections.filter((a) => a.id === this.electionId)[0].winners;
    },
    electionResults: function () {
      let candidateResults = this.candidates;

      candidateResults.sort((a, b) => b.votes - a.votes);
      for (let i = 0; i < candidateResults.length; i++) {
        if (i === this.electionWinners - 1 && i < candidateResults.length - 1) {
          if (candidateResults[i].votes !== candidateResults[i + 1].votes) {
            candidateResults[i].winner = "true";
          } else {
            candidateResults[i].winner = "tie";
          }
        } else if (i < this.electionWinners) {
          candidateResults[i].winner = "true";
        } else if (
          candidateResults[i].votes === candidateResults[i - 1].votes &&
          (candidateResults[i - 1].winner === "true" ||
            candidateResults[i - 1].winner === "tie")
        ) {
          candidateResults[i].winner = "tie";
        } else {
          candidateResults[i].winner = "false";
        }
      }

      return candidateResults;
    },
    voterVoteTotal: function () {
      return this.voters
        .map((e) => e.votes * e.voted)
        .reduce((prevTotal, currentValue) => prevTotal + currentValue, 0);
    },
    candidateVoteTotal: function () {
      return this.candidates
        .map((e) => e.votes)
        .reduce((prevTotal, currentValue) => prevTotal + currentValue, 0);
    },
  },
  methods: {
    getApiData: async function (endpoint, varName) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data.sort(
              (a, b) => a.sequence - b.sequence
            ))
        );
    },
    getCandidates: async function () {
      await this.getApiData(
        `/candidate/election_id/${this.electionId}`,
        "candidates"
      );
      await this.getApiData(`/voter/election_id/${this.electionId}`, "voters");
    },
  },
};
</script>

<style>
/* Further condense vertical spacing */
.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
