<template>
  <v-app>
    <v-app-bar app dense color="primary" dark>
      <v-app-bar-title> ABR Elections </v-app-bar-title><v-spacer></v-spacer
      ><v-app-bar-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon to="/" v-bind="attrs" v-on="on">
              <v-icon>mdi-vote-outline</v-icon>
            </v-btn>
          </template>
          <span>Vote</span>
        </v-tooltip>
        <!-- <v-tooltip bottom> -->
        <!--   <template v-slot:activator="{ on, attrs }"> -->
        <!--     <v-btn icon to="voted" v-bind="attrs" v-on="on"> -->
        <!--       <v-icon>mdi-account-multiple</v-icon> -->
        <!--     </v-btn> -->
        <!--   </template> -->
        <!--   <span>Voters</span> -->
        <!-- </v-tooltip> -->
        <!-- <v-tooltip bottom> -->
        <!--   <template v-slot:activator="{ on, attrs }"> -->
        <!--     <v-btn icon to="results" v-bind="attrs" v-on="on"> -->
        <!--       <v-icon>mdi-check-bold</v-icon> -->
        <!--     </v-btn> -->
        <!--   </template> -->
        <!--   <span>Results</span> -->
        <!-- </v-tooltip> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon to="help" v-bind="attrs" v-on="on">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>Help</span>
        </v-tooltip>
      </v-app-bar-title>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
